import { useEffect } from "react";
import { Link } from "react-router-dom"
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import BgLiveImage from "../Components/Ui/BgLiveImage";

import { isLogin } from '../utils';

export default function PublicPage() { 

    useEffect(() => {
      document.body.classList.add('countdown');
      return () => {
        document.body.classList.remove('countdown');
      }
    }, [])
    

    return (
        <div className="base-box" id="page-top">
        
        <Header hideOptions={true} />
          
        <div className="base-item">
          <section className="hero-content flex-box stretch bg-1">
            <div className="item-box flex-box stretch full-size">
              <div className="bg-image">
                <figure className="image-box">
                  <BgLiveImage />
                </figure>
              </div>
  
              <div className="hero-item-box flex-box wrap top-center count-to-zero">
                <div className="hero-item-wrapper">
                  <div className="text-box cl-white pd-t5">
                    <h1>Live in Berlin – Digital Worldwide</h1>
                    <ul className="hashtag">
                      <li>
                        Valuable &amp; Customized Content I Learn Something New
                        Today
                      </li>
                      <li>
                        Individual Matchmaking I Meet the Right Person and Company
                        Today
                      </li>
                      <li>
                        Company Spaces I Find Your Perfect Solution and Partner
                        Today
                      </li>
                      <li>
                        Appointments, Text &amp; Videochats I Get Connected Today
                      </li>
                    </ul>
                    <br />
                    <br />
                  </div>
                  <div className="col-box col-2 teaser-box cl-white">
                    <div className="col-item bg-1 cl-white">
                      <div className="content-box">
                        <h3>Get Your Ticket</h3>
                        <p>
                          You should be with us!
                          <br />
                          Visit CCW physically and/or digitally!
                        </p>
                      </div>
                      <div className="button-box" style={{'display': 'flex-box'}}>
                        <a href="https://www.ccw.eu/tickets/" className="button call-to-action" target="_new" style={{"marginBottom" :'1rem'}}>Registrieren Sie sich (Deutsch)</a>
                        <br />
                        <a href="https://www.ccw.eu/en/tickets/ " className="button  bg-10" target="_new">Register now (English)</a>
                      </div>
                    </div>
  
                    <div className="col-item bg-1 cl-white">
                      <div className="content-box">
                        <h3>For Ticketholders</h3>
                        <p>
                          <strong>Conference / Trade Show / Exhibitor</strong>
                          <br />
                          <br />
                          To get the most out of digital CCW
                          <br />
                          make sure to complete your user profile!
                        </p>
                      </div>
                      <div className="button-box">

                      <Link style={{ "marginBottom": "1rem" }} className="button call-to-action" title="Login" to="/user-editor">
                          Complete User Profile
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                      </Link>
                      <Link className="button bg-10" title="Login" to="/user-login">
                          Login
                      </Link>
                        {/* {isLogin() ? (
                          <Link className="button bg-10" title="Login" to="/live">
                              Live
                          </Link>
                        ) : (
                          <Link className="button bg-10" title="Login" to="/user-login">
                              Login
                          </Link>
                        ) } */}
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    )

}