import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

export default function Footer ({type}) {
    const authContext = useContext(AuthContext);

    return (
        <footer id="colophon" className={`site-footer clear ${type =='live' ? '' : 'pd-3'} bg-black live-footer`} role="contentinfo">

            {type == 'live' ? (
                <section class="footer-meta-box bg-1">
                <div class="bg-image">
                        <figure class="image-box">
                                <img srcSet={`${process.env.REACT_APP_ENDPOINT}images/footer-bg-790.jpg 760w,
                                ${process.env.REACT_APP_ENDPOINT}images/footer-bg.jpg 1080w`} sizes="(max-width: 1600px) 100vw, 1600px" src={process.env.REACT_APP_ENDPOINT+"/images/footer-bg.jpg"} /> 
                        </figure>
                </div>

                <div class="item-box pd-b5 pd-t8">
                    <div class="flex-box top-right footer-content-box">
                        <div class="flex-item">
                            <p><strong>Organiser</strong></p>
                            <img src={process.env.REACT_APP_ENDPOINT+"/images/management-circle-logo.svg"} />
                            <p>Education for the best. Since 1989, executives from all areas of the company have placed their trust in the high standard of our seminars, conferences and congresses. Benefit from an optimal connection of knowledge transfer and networking.</p>
                        </div>
                    </div>
                    <div class="social_media-box flex-box">
                        <a href="https://www.linkedin.com/showcase/ccw-berlin-conference-and-tradeshow-for-innovative-customer-dialogue" class="facebook linkedin" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="25px" height="25px"><path fill="#fff" d="M 8 3.0097656 C 4.53 3.0097656 2.0097656 5.0892187 2.0097656 7.9492188 C 2.0097656 10.819219 4.59 12.990234 8 12.990234 C 11.47 12.990234 13.990234 10.870625 13.990234 7.890625 C 13.830234 5.020625 11.36 3.0097656 8 3.0097656 z M 3 15 C 2.45 15 2 15.45 2 16 L 2 45 C 2 45.55 2.45 46 3 46 L 13 46 C 13.55 46 14 45.55 14 45 L 14 16 C 14 15.45 13.55 15 13 15 L 3 15 z M 18 15 C 17.45 15 17 15.45 17 16 L 17 45 C 17 45.55 17.45 46 18 46 L 27 46 C 27.552 46 28 45.552 28 45 L 28 30 L 28 29.75 L 28 29.5 C 28 27.13 29.820625 25.199531 32.140625 25.019531 C 32.260625 24.999531 32.38 25 32.5 25 C 32.62 25 32.739375 24.999531 32.859375 25.019531 C 35.179375 25.199531 37 27.13 37 29.5 L 37 45 C 37 45.552 37.448 46 38 46 L 47 46 C 47.55 46 48 45.55 48 45 L 48 28 C 48 21.53 44.529063 15 36.789062 15 C 33.269062 15 30.61 16.360234 29 17.490234 L 29 16 C 29 15.45 28.55 15 28 15 L 18 15 z"/></svg>
                        </a>
                        <a href="https://www.facebook.com/ccw.eu/" class="facebook" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fff" d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z"></path></svg>
                            </a>
                        <a href="https://twitter.com/ccw_berlin" class="twitter" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fff" d="M32 7.075a12.941 12.941 0 01-3.769 1.031 6.601 6.601 0 002.887-3.631 13.21 13.21 0 01-4.169 1.594A6.565 6.565 0 0022.155 4a6.563 6.563 0 00-6.563 6.563c0 .512.056 1.012.169 1.494A18.635 18.635 0 012.23 5.195a6.56 6.56 0 00-.887 3.3 6.557 6.557 0 002.919 5.463 6.565 6.565 0 01-2.975-.819v.081a6.565 6.565 0 005.269 6.437 6.574 6.574 0 01-2.968.112 6.588 6.588 0 006.131 4.563 13.17 13.17 0 01-9.725 2.719 18.568 18.568 0 0010.069 2.95c12.075 0 18.681-10.006 18.681-18.681 0-.287-.006-.569-.019-.85A13.216 13.216 0 0032 7.076z"></path></svg>
                        </a>
                        <a href="https://www.youtube.com/user/CallCenterWorld" class="youtube" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fff" d="M31.681 9.6s-.313-2.206-1.275-3.175C29.187 5.15 27.825 5.144 27.2 5.069c-4.475-.325-11.194-.325-11.194-.325h-.012s-6.719 0-11.194.325c-.625.075-1.987.081-3.206 1.356C.631 7.394.325 9.6.325 9.6s-.319 2.588-.319 5.181v2.425c0 2.587.319 5.181.319 5.181s.313 2.206 1.269 3.175c1.219 1.275 2.819 1.231 3.531 1.369 2.563.244 10.881.319 10.881.319s6.725-.012 11.2-.331c.625-.075 1.988-.081 3.206-1.356.962-.969 1.275-3.175 1.275-3.175s.319-2.587.319-5.181v-2.425c-.006-2.588-.325-5.181-.325-5.181zM12.694 20.15v-8.994l8.644 4.513-8.644 4.481z"></path></svg>
                        </a>
                    </div>
                </div>
            </section>
            ) : ''}
            
			{/* <a href="#page-top" className="totop"><span className="icon-box arrow"><i className="icon"></i></span></a> */}
			<section className={`footer-box ${type =='live' ? 'pd-3' : ''}`}>
                <div className="item-box flex-box">        
                        <span className="copyright">© Management Circle AG 2023</span>
                        <nav className="footer-nav">
                            {authContext.isAuth ? <></> : (
                                <Link to="/exhibitor-login" ><span>Exhibitor Admin Login</span></Link>
                            )}
                            <Link to={'/faq#v21'}>
                                <span className="cl-5">Help Chat</span>
                            </Link>
                            <a target="_blank" href="https://jitsi.ccw.eu/system-check/" className="system-check">
                                <span>System Check</span>
                            </a>
                            <a href="https://www.ccw.eu/en/legal-notice/" target="_blank"><span>Legal Notice</span></a>
                            <a href="https://www.ccw.eu/en/data-privacy-statement/" target="_blank"><span>Data Privacy</span></a>
                            <Link to={'/faq'}>
                                <span>FAQ</span>
                            </Link>
                        </nav>
                    
                    </div>
                </section>
    </footer>
    )
}