import { useState } from "react";
import { useContext, useEffect } from "react";
import { LivestreamContext } from "../Context/LivestreamContext";
import overlayContext from "../Context/overlayContext";
import { cryptedUserId, decryptedUserId, getCookie } from "../utils";
import { doRequest } from "../utils/doRequest";
import parse from "html-react-parser";

export default function StreamVideo() {
	const { overlayData, setShowoverlay } = useContext(overlayContext);
	const { livestream } = useContext(LivestreamContext);
	const [ activestream, setActiveStream] = useState({});
	const [ startNextStream, setStartNextStream ] = useState(false);


	function sendAnalyticsStream(company_id, activity, title,eaagent) {
		console.log(company_id,'Report analysis');
	
		company_id.forEach(element => {

			doRequest('typeNum', '/?type=1625139860' , 'typeNum-GET', {
				cryptedUserId : cryptedUserId(),
				company_id : element.companyid,
				activity: activity,
				title: title,
				eaagent: eaagent
			  },'', (err, data) => {
				console.log(data);
			  })
			
		});

	}

	useEffect(() => {

		let streamUid = livestream.filter((stream) => stream.stage == overlayData.stage)[0].uid;
		let activeStreamUid = activestream.uid;
		
		if(streamUid !== activeStreamUid && activeStreamUid) {
			setStartNextStream(true);
		}
		setActiveStream(livestream.filter((stream) => stream.stage == overlayData.stage)[0])

	}, [livestream, overlayData])

	useEffect(() => {
		sendAnalyticsStream(overlayData.companyId , 'stream' , overlayData.title, 0 );
	}, [overlayData])

	if(startNextStream) {
		
		const timeout = setTimeout(() => {
			console.log("clear timeout called")
			setShowoverlay('');
		}, 30000);

		function startNextVideoStream() {
			clearTimeout(timeout)
			sendAnalyticsStream(activestream.companyId , 'stream' , activestream.title, 0 );
			setStartNextStream(false);
		}

		return (
			<div id="stream-video-container">
				<div className="col-box col-2 live-stream-box">
					<div className="next-overlay" ></div>
					<button id="watch-next-btn" onClick={() => startNextVideoStream()} >This stream is over, the next stream will start soon. Click here to load it.</button>
				</div>
			</div>
		)
	}
	
    return (
		<div id="stream-video-container">
			<div className="col-box col-2 live-stream-box">
				<div className={`col-item box-70 ${ (activestream.stage != 5 || activestream.stage != 6)  ? 'fullwidth' : ''} `}>
					{overlayData.showDeStream ? (
						activestream.dehtmllivestream ? (
							<div className="iframe-box" style={{"height":"100%" }}>
								<iframe id="vimeo-player" src={activestream.dehtmllivestream} width="600" height="100%"  allow="autoplay; fullscreen" allowFullScreen="" style={{"height": "100%"}}></iframe>
							</div>
						) : ''
					) : (
						activestream.htmllivestream ? (
							<div className="iframe-box" style={{"height":"100%" }}>
								<iframe id="vimeo-player" src={activestream.htmllivestream} width="600" height="100%"  allow="autoplay; fullscreen" allowFullScreen="" style={{"height": "100%"}}></iframe>
							</div>
						) : ''
					)}
					
					<h5 className="cl-4"> {activestream.stageTitle} {activestream.detitle && parse(activestream.detitle)}</h5>
					<h5 className="cl-4"> {activestream.stageTitle} {activestream.title && parse(activestream.title)}</h5>
				</div>
				
				{(activestream.stage == 5 || activestream.stage == 6) ? (
					<div className="col-item chat-box bg-3 box-30">
					{activestream.stage == 5 && (
						<iframe src="https://app.sli.do/event/19bzwPCr4aKL6FR3NkQdXj" height="100%" width="100%" frameBorder="0" style={{"minHeight": "590px"}} title="Slido"></iframe>
					)}
					
					{activestream.stage == 6 && (
						<iframe src="https://app.sli.do/event/19bzwPCr4aKL6FR3NkQdXj" height="100%" width="100%" frameBorder="0" style={{"minHeight": "590px"}} title="Slido"></iframe>
					)}
				</div>
				) : ('')}

			</div>
		
			
			
			<script src="https://player.vimeo.com/api/player.js"></script>
		</div>
    )
}