import * as React from "react";
import { useEffect, useContext } from "react";

import Matchlinks from "./Matchlinks";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId, getCookie, jumpToSection } from "../utils";
import { BookmarkContext } from "../Context/BookmarkContext";
import overlayContext from "../Context/overlayContext";
import { ProfileContext } from "../Context/ProfileContext";
import { LoadingContext } from "../Context/LoadingContext";
import { AuthContext } from "../Context/AuthContext";
import MatchmkaingCard from "../Components/Ui/MatchmkaingCard";
import { BeatLoader } from "react-spinners";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

export default function MatchFilter({type}) {
    
    const { profile } = useContext(ProfileContext);
    const bookmarkContext = useContext(BookmarkContext);
    const authContext = useContext(AuthContext);
    let filterLimit = 30;

    const [filterVar, setFilterVar] = React.useState([]);
    const [tempUsers, setTempUsers] = React.useState([]);
    const [matchUsers, setMatchUsers] = React.useState([]);
    const [usersCount, setUsersCount] = React.useState(0);
    const [paginatedUsers, setPaginatedUsers] = React.useState([]);
    const [paginationOutOfCount, setPaginationOutOfCount] = React.useState(0);
    const [activeItem, setActiveItem] = React.useState(1);
    const [paginationLinks, setPaginationLinks] = React.useState([]);
    
    const [positionFilter, setPositionFilter] = React.useState(0);
    const [industryFilter, setIndustryFilter] = React.useState(0);
    const [expertiseFilter, setExpertiseFilter] = React.useState(0);
    const [interestFilter, setInterestFilter] = React.useState(0);
    const [freeTextFilter, setFreeTextFilter] = React.useState('');
    const [showNoResult, setShowNoResult ] = React.useState(0);
    const [isUpdated, setIsUpdated] = React.useState(0);
    
    const { setLoading } = useContext(LoadingContext);
    const { hash } = useLocation();

    useEffect(() => {
        if (authContext.profile.activatematch == 0) {
            window.location.href = `${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-login?ses_id=${getCookie('fe_typo_user')}&return_url=${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-editor?step=4`;
        }
    }, [authContext.profile.activatematch] )

    useEffect(() => {
        setIsUpdated(!isUpdated);
    }, [bookmarkContext.bookmark]);

    useEffect(() => {
        setLoading(true);
        if(window.location.hash == ''){
            setActiveItem(1);
            window.location.hash = '#1';
        } else {
            setActiveItem(window.location.hash.split('#')[1]);
        }
       
        if(type != localStorage.getItem('filterFor')){
            localStorage.setItem('position', 0);
            localStorage.setItem('industry', 0);
            localStorage.setItem('areaOfInterest', 0);
            localStorage.setItem('areaOfExpertise', 0);
            localStorage.setItem('match-filter-btn', '');
        } else {
            setPositionFilter(localStorage.getItem('position'));
            setIndustryFilter(localStorage.getItem('industry'));
            setExpertiseFilter(localStorage.getItem('areaOfExpertise'));
            setInterestFilter(localStorage.getItem('areaOfInterest'));
            setFreeTextFilter(localStorage.getItem('match-filter-btn'));
            setIsUpdated(!isUpdated);
        }

        doRequest('React', '/lib/storage/content/position-filter.json' , 'GET', {}, true, (err,position) => {
            doRequest('React', '/lib/storage/content/industry-filter.json' , 'GET', {}, true, (err,industry) => {
                doRequest('React', '/lib/storage/content/areas_of_expertise-filter.json' , 'GET', {}, true, (err,expertise) => {
                    doRequest('React', '/lib/storage/content/areas_of_interest-filter.json' , 'GET', {}, true, (err,interest) => {
                        setLoading(false);
                        setFilterVar(
                            {
                                'position': position,
                                'industry': industry,
                                'expertise': expertise,
                                'interest': interest
                            }
                        );
                        window.scrollTo(0, 0);
                    });
                });
            });
        });

        doRequest('React', '/lib/storage/users/' + decryptedUserId() + '/bookmark.json' , 'GET', {}, true, (err,bookmarks) => {
            bookmarkContext.setBookmark(bookmarks);
        });
        
    }, []);

    useEffect(() => {
        if(type == 'matchmaking') {
            setLoading(true);
            let freeFilter = document.getElementById('freeTextFilter').value;
            doRequest('Server', 'server.php', 'GET', 
            { 
                type: 'filterActiveMatchUsers', 
                user: decryptedUserId(), 
                activeItem: activeItem, 
                filterLimit : filterLimit,
                positionFilter: positionFilter,
                industryFilter: industryFilter,
                expertiseFilter: expertiseFilter,
                interestFilter: interestFilter,
                freeTextFilter: freeFilter
            }, true, (err,data) => {
                setLoading(false);
                if(data.response.users.length == 0) {
                    setShowNoResult(1)
                } else {
                    setShowNoResult(0)
                }
                setMatchUsers(data.response.users);
                setTempUsers(data.response.users);
                setPaginatedUsers(data.response.users);
                setUsersCount(data.response.users_count);
                calculatePaginationLinks(data.response.total_pages);
                setPaginationOutOfCount(data.response.total_pages);
            })
        }

        if(type == 'contacts') {
            setLoading(true);
            let freeFilter = document.getElementById('freeTextFilter').value;
            doRequest('Server', 'server.php', 'GET', { 
                type: 'matchContacts', 
                user: decryptedUserId(), 
                activeItem: activeItem, 
                filterLimit : filterLimit,
                positionFilter: positionFilter,
                industryFilter: industryFilter,
                expertiseFilter: expertiseFilter,
                interestFilter: interestFilter,
                freeTextFilter: freeFilter
            }, true, (err,data) => {
                setLoading(false);
                if(data.response.users.length == 0) {
                    setShowNoResult(1)
                } else {
                    setShowNoResult(0)
                }
                setMatchUsers(data.response.users);
                setTempUsers(data.response.users);
                setPaginatedUsers(data.response.users);
                setUsersCount(data.response.users_count);
                calculatePaginationLinks(data.response.total_pages);
                setPaginationOutOfCount(data.response.total_pages);
            })
        }

        if(type == 'bookmark') {
            setLoading(true);
            let freeFilter = document.getElementById('freeTextFilter').value;
            doRequest('Server', 'server.php', 'GET', { 
                type: 'matchBookmarks', 
                user: decryptedUserId(), 
                activeItem: activeItem, 
                filterLimit : filterLimit,
                positionFilter: positionFilter,
                industryFilter: industryFilter,
                expertiseFilter: expertiseFilter,
                interestFilter: interestFilter,
                freeTextFilter: freeFilter
            }, true, (err,data) => {
                setLoading(false);
                if(err) {
                    setMatchUsers([]);
                    setTempUsers([]);
                    setUsersCount(0);
                    setPaginatedUsers([]);
                    calculatePaginationLinks(0);
                    setPaginationOutOfCount(0);
                } else {
                    if(data.response.users.length == 0) {
                        setShowNoResult(1)
                    } else {
                        setShowNoResult(0)
                    }
                    setMatchUsers(data.response.users);
                    setTempUsers(data.response.users);
                    setUsersCount(data.response.users_count);
                    setPaginatedUsers(data.response.users);
                    calculatePaginationLinks(data.response.total_pages);
                    setPaginationOutOfCount(data.response.total_pages);
                }
            })
        }
        
    }, [activeItem, isUpdated]);

    function calculatePaginationLinks(paginationCount) {
        let paginationLinkArr = [];
        console.log(activeItem);
        console.log(paginationCount);
        
        if (activeItem <= 9 && paginationCount <= 9) {
            for(let i=1; i <= paginationCount; i++) {
                paginationLinkArr.push(i);
            }
        } else {
            if (activeItem <= 4) {
                for(let i=1; i <= paginationCount; i++) {
                    if(i <= 6) {
                        paginationLinkArr.push(i)
                    }
                    if(i == 7) {
                        paginationLinkArr.push('...')
                    }
                    if(i > (paginationCount - 3)) {
                        paginationLinkArr.push(i)
                    }                   
                }
            } 

            if (activeItem >= paginationCount - 4)
            {
                for(let i=1; i <= paginationCount; i++) {
                    if(i <= 3) {
                        paginationLinkArr.push(i)
                    }
                    if(i == 4) {
                        paginationLinkArr.push('...')
                    }
                    if(i > (paginationCount - 6)) {
                        paginationLinkArr.push(i)
                    }
                }
            }
            
            if(activeItem >= 5 && activeItem <= paginationCount - 5)
            {

                for(let i=1; i <= paginationCount; i++) {
                    if(i < 4) {
                        paginationLinkArr.push(i)
                    }
                    if(i == 4) {
                        paginationLinkArr.push('...')
                    }
                    
                    if(i == activeItem - 1) {
                        paginationLinkArr.push(i)
                    }

                    if(i == activeItem) {
                        paginationLinkArr.push(i)
                    }

                    if(i == (parseInt(activeItem, 10) + 1)) {
                        paginationLinkArr.push(i)
                    }

                    if(i == paginationCount - 3) {
                        paginationLinkArr.push('...')
                    }
                    if(i > paginationCount - 3) {
                        paginationLinkArr.push(i)
                    }
                }
            }
             
            

            
        }

        setPaginationLinks(paginationLinkArr);
    }

    function filterRecs(event) {
        let filterSelection = event.target.getAttribute('id');
        let filterClass = event.target.getAttribute('data-id');
        
        let filterVal = event.target.value ? event.target.value : 0;
        localStorage.setItem('filterFor', type);
        if (filterSelection == 'position') {
            setPositionFilter(filterVal);
            localStorage.setItem('position', filterVal);
        } else if (filterSelection == 'industry') {
            setIndustryFilter(filterVal);
            localStorage.setItem('industry', filterVal);
        } else if (filterSelection == 'areaOfInterest') {
            setInterestFilter(filterVal);
            localStorage.setItem('areaOfInterest', filterVal);
        } else if (filterSelection == 'areaOfExpertise') {
            setExpertiseFilter(filterVal);
            localStorage.setItem('areaOfExpertise', filterVal);
        } else if (filterClass == 'match-filter-btn') {
            let filterVal = document.getElementById('freeTextFilter').value ? document.getElementById('freeTextFilter').value : '';
            setFreeTextFilter(filterVal);
            localStorage.setItem('match-filter-btn', filterVal);
        }
        setIsUpdated(!isUpdated);
        setActiveItem(1);
    }

    function resetFilter(event) {
        document.getElementById('position').value = '';
        document.getElementById('industry').value = '';
        document.getElementById('areaOfInterest').value = '';
        document.getElementById('areaOfExpertise').value = '';
        document.getElementById('freeTextFilter').value = '';
        setPositionFilter(0);
        setIndustryFilter(0);
        setInterestFilter(0);
        setExpertiseFilter(0);
        setFreeTextFilter('');
        localStorage.setItem('position', 0);
        localStorage.setItem('industry', 0);
        localStorage.setItem('areaOfInterest', 0);
        localStorage.setItem('areaOfExpertise', 0);
        localStorage.setItem('match-filter-btn', '');
        window.location.hash = '#1';
        filterRecs(event);
        setActiveItem(1);
    }

    const handleKeyDownFilter = (event) => {
        if (event.key === 'Enter') {
            event.target.setAttribute('data-id', 'match-filter-btn');
            filterRecs(event);
        }
    }
   
    const navigate = useNavigate();
    function switchPage(page) {
        let offset = filterLimit * (page-1);
        setPaginatedUsers(
            matchUsers.slice(offset,offset+filterLimit) 
        );
        navigate('#'+page);
        setActiveItem(page)
    }


    function paginateScroll()
    {
        if(window.screen.width < 768){
            jumpToSection('#scrolltosectionMob');
        } else {
            jumpToSection('#scrolltosection');
        }
    }

    function paginateRec(event) {
        let page = parseInt(event.target.getAttribute('data-page'));
        if(page && page != activeItem) { 
            paginateScroll();
            switchPage(page)
        } 
    }

    function firstPage() {
        switchPage(1)
        paginateScroll();
        setActiveItem(1);
    }

    function prevPage(event) {
        if (activeItem > 1) {
            switchPage(activeItem-1)
            paginateScroll();
            setActiveItem(activeItem-1);
        }
    }

    function nextPage(event) {
        if (activeItem < paginationOutOfCount) {
            switchPage(activeItem+1)
            paginateScroll();
            setActiveItem(activeItem+1);
        }
    }

    function lastPage() {
        if (activeItem < paginationOutOfCount) {
            switchPage(paginationOutOfCount)
            paginateScroll();
            setActiveItem(paginationOutOfCount);
        }
    }

    function Paginate() {
        if (paginationLinks.length) {
            return (
                <div className="results-pagination flex-box flex-item box-100 pd-3">
                    <button className="tab-previous-page first-page" id="results-first" onClick={firstPage}><span className="icon-box arrow"><i className="icon"></i></span></button>
                    <button className="tab-previous-page" id="results-previous" onClick={prevPage}><span className="icon-box arrow"><i className="icon"></i></span></button>            
                    <div className="page-links-container">
                        {paginationLinks.map((item,val) => {
            
                            let activeClass = '';
                            if (item == activeItem) {
                                activeClass = 'active';
                            }
                    
                            return (
                                <button key={val} href="#!" className={`match-page-link ${activeClass}`} data-page={item} onClick={paginateRec}>
                                    <span className="button-label" data-page={item}>{item}</span>
                                    <span className="icon-box"><i className="icon"></i></span>
                                </button>
                            );
                        })} 
                        
                    </div>
                    <button className="tab-next-page" id="results-next" onClick={nextPage}><span className="icon-box arrow"><i className="icon"></i></span></button>
                    <button className="tab-next-page last-page" id="results-last" onClick={lastPage}><span className="icon-box arrow"><i className="icon"></i></span></button>
            </div>
            )
        } else {
            return (
                <div className="results-pagination"></div>
            );
        }
    }
    return (
    <div id="scrolltosection">
        <section className="modul user-matchmaking  bg-white" id="filter-nav-section">
            <div className="item-box pd-t5 ">
                
                <div className="nav-box flex-box wrap">
                    <Matchlinks />
                    <button className="button reset-button" id="reset-filter" onClick={resetFilter}><span onClick={resetFilter} className="button-label">reset all Filters</span><span className="icon-box reset"><i className="icon"></i></span></button>
                </div>
                <div className="matchmaking-filter-box flex-box wrap filters">
                    <div className="matchmaking-filter-hashtag ui-group">
                        <select className="filter-select" data-questionid="6" value-group="hashtag" id="position" onChange={filterRecs}>
                            <option value="">Filter by Management Level</option>
                            {filterVar.position?.map((item, val) => {
                                return (
                                    <option selected={positionFilter == item.uid} key={item.uid} value={item.uid}>{item.answer}</option>
                                );
                            })}
                        </select>
                        <span className="icon-box select"><i className="icon"></i></span>
                    </div>
                    <div className="matchmaking-filter-top-match ui-group">
                        <select className="filter-select" data-questionid="5" value-group="top-match" id="industry" onChange={filterRecs}>
                            <option value="">Filter by Industry</option>
                            {filterVar.industry?.map((item, val) => {
                                return (
                                    <option selected={industryFilter == item.uid} key={item.uid} value={item.uid}>{item.answer}</option>
                                );
                            })}
                        </select>
                        <span className="icon-box select"><i className="icon"></i></span>
                    </div>
                    <div className="matchmaking-filter-f2 ui-group">
                        <select className="filter-select" data-questionid="4" value-group="f2" id="areaOfExpertise" onChange={filterRecs}>
                            <option value="">Filter by Area Of Expertise</option>
                            {filterVar.expertise?.map((item, val) => {
                                return (
                                    <option selected={expertiseFilter == item.uid} key={item.uid} value={item.uid}>{item.answer}</option>
                                );
                            })}
                        </select>
                        <span className="icon-box select"><i className="icon"></i></span>
                    </div>
                    <div className="matchmaking-filter-f1 ui-group">
                        <select className="filter-select" data-questionid="4" value-group="f1" id="areaOfInterest" onChange={filterRecs}>
                            <option value="">Filter by Area Of Interest</option>
                            {filterVar.interest?.map((item, val) => {
                                return (
                                    <option selected={interestFilter == item.uid} key={item.uid} value={item.uid}>{item.name}</option>
                                );
                            })}
                        </select>
                        <span className="icon-box select"><i className="icon"></i></span>
                    </div>
                </div>
                <div className="free-text-search flex-box">
                    <input type="text" value={freeTextFilter} onChange={(e) => setFreeTextFilter(e.target.value)} id="freeTextFilter" onKeyDown={handleKeyDownFilter} placeholder="Search for anybody or anything" className="text-search"/>
                    <button className="button" data-id="match-filter-btn" id="match-filter-btn" type="button" onClick={filterRecs}>
                        <span className="button-label" data-id="match-filter-btn">Search</span>
                        <span className="icon-box search" data-id="match-filter-btn"><i className="icon"></i></span>
                    </button>
                </div>
                
            </div>
        </section>

        <section className="modul user-matchmaking bg-white">
            <div className="item-box pd-t0">
                <div className="flex-box wrap pd-t3 pd-b2">
                    <div className="flex-item filter-result-counter">
                        <strong>RESULTS: <span id="filter-result-counter">{usersCount}</span></strong>
                    </div>
                </div>
            </div>
        </section>

        <section className="modul user-matchmaking bg-4" id="scrolltosectionMob">
            <div className="item-box">
                {showNoResult ? ( 
                    <div className="matchmaking-results-box">
                        <form action="" method="post">
                            <div id="ajaxResponse" className="match-results">
                                <div class="pd-5 matchmaking-results-box">
                                    <div id="holmes-placeholder">
                                        <h3>— No results. —</h3>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>) : (
                    authContext.profile.activatematch ? (<>
                        <Paginate />               
                        <div className="matchmaking-results-box">
                            <form action="" method="post">
                                <div id="ajaxResponse" className="match-results">



                                {!paginatedUsers.length ? (<div class="pd-5 matchmaking-results-box">
                                    <div id="holmes-placeholder">
                                    {showNoResult ? (<h3>— No results. —</h3>) : <BeatLoader /> }
                                    </div>
                                </div>) : '' }
                                
                                {paginatedUsers.map((item,val) => {
                                    return (<MatchmkaingCard item={item} />);
                                })}
                                </div>
                            </form>
                        </div>
                        <Paginate />
                    </>) : 'Please Activate matchmaking'
                ) }

                
            </div>
        </section>



    </div>
    );
}