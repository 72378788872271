import React, { Fragment, useContext } from 'react'
import { contentStyle } from '../../utils';
import parse from "html-react-parser";
import { Link } from 'react-router-dom';
import overlayContext from '../../Context/overlayContext';

export default function Podcast({item, company, isOverlay}) {
    let style = contentStyle(item.style);
    const { setShowoverlay, setOverlayData, overlayData } = useContext(overlayContext);

    function showOverlay() {
      setOverlayData({
        item: item,
        company: company
      });

      setShowoverlay('csPodcast');

    }

    return (
      <div className={`flex-item company-live-ajax ${style} ${item.class ? item.class : 'bg-10'} podcast-box`} >
               <div className="box-title">

                  {isOverlay ? (
                     <button onClick={() => showOverlay()} className='button podcast-opener'>
                     <span className="button-label">Podcast</span>
                     <span className="icon-box play-movie"><i className="icon"></i></span>
                     </button>
                  ) : (
                     <Link to={`/company/${item?.company?.uid}#our-content`} className="button podcast-opener">
                        <span className="button-label">Podcast</span>
                        <span className="icon-box podcast">
                           <i className="icon"></i>
                        </span>
                     </Link>
                  )} 

                 
               </div>

               {item.image? (
                  <figure className="image-box">
                     <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + item.image} alt={item.title} />
                  </figure>
               ) : ''}
               
               <div className="text-box cl-white">
                     <h4>{item.title ? item.title : item.podcastheadline}</h4>
                     {item.podcastdescription &&  parse(item.podcastdescription)}
               </div>

               <div className="product-overview-box cl-white">
                  {item.expertise && item.expertise.map((topic, index) => (
                     <Fragment key={index}><span> # {topic}</span> <br /></Fragment>
                  ) )}
               </div>
         </div>
    )
}
