import { useContext, useEffect } from "react";
import overlayContext from "../Context/overlayContext";

import { AuthContext } from "../Context/AuthContext";

export default function StickyLinks () {
    const { showOverlay, setShowoverlay } = useContext(overlayContext);
    const { profile } = useContext(AuthContext);
    useEffect(() => {
      console.log(profile)
    }, [profile])
    
    return (
        <div className="static-links">
            {/* <button className="static-link button" onClick={() => setShowoverlay('feedback')} id="feedback-opener" >
                <span className="static-link-label">Feedback</span>
                <span className="icon-box feedback"><i className="icon"></i></span>
            </button> */}
            <button className="static-link button" onClick={() => setShowoverlay('tutorial')} id="tutorial-opener" >
                <span className="static-link-label">Help</span>
                <span className="icon-box play-movie"><i className="icon"></i></span>
            </button>
            <button className="static-link button" onClick={() => setShowoverlay('hallplan')} id="hallplan-opener">
                <span className="static-link-label">Hall Plan</span>
                <span className="icon-box locator"><i className="icon"></i></span>
            </button>

            {/* <button className="static-link button" onClick={() => setShowoverlay('hallplan')} id="feedback-opener">
                <span className="static-link-label">feedback</span>
                <span className="icon-box ai"><i className="icon"></i></span>
            </button> */}
            {/* {profile.conferenceuser ? (
                <button className="static-link button" onClick={() => setShowoverlay('ai-feedback')} id="ai-opener">
                    <span className="static-link-label">AI</span>
                    <span className="icon-box ai"><i className="icon"></i></span>
                </button>
            ) : ''} */}
            

        </div>
    )
}