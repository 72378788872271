import React, { Fragment, useContext } from 'react'
import parse from "html-react-parser";
import { contentStyle } from '../../utils';
import { Link } from 'react-router-dom';
import overlayContext from '../../Context/overlayContext';

export default function Video({item, company, isOverlay}) {
    let style = contentStyle(item.style);

    const { setShowoverlay, setOverlayData, overlayData } = useContext(overlayContext);

    function showOverlay() {
      setOverlayData({
        item: item,
        company: company
      });

      setShowoverlay('csVideo');

    }

    return (
      <div className={`flex-item ${style} company-live-ajax ${item.class ? item.class : 'bg-10'}`} >

            <div className="box-title">
                {isOverlay ? (
                  <button onClick={() => showOverlay()} className='button video-opener'>
                    <span className="button-label">Video</span>
                    <span className="icon-box play-movie"><i className="icon"></i></span>
                  </button>
                ) : (
                  <Link to={`/company/${item?.company?.uid}#our-content`} className="button video-opener">
                    <span className="button-label">Video</span>
                    <span className="icon-box play-movie"><i className="icon"></i></span>
                    <input type="hidden" className="activity-title" value="Digital Sales Marketing" />
                </Link>
                )} 
            </div>

            {item.image? (
              <figure className="image-box">
                <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + item.image} alt={item.title} />
              </figure>
            ) : ''}
            
            <div className="text-box cl-white">
                  <h4>{item.title ? item.title : item.videoheadline}</h4>
                  {item.videodescription && parse(item.videodescription)}
            </div>

            <div className="product-overview-box cl-white">
                  {item.expertise && item.expertise.map((topic, index) => (
                      <Fragment key={index}><span> # {topic}</span> <br /></Fragment>
                  ) )}
            </div>

      </div>
    )
}
