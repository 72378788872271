import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { isLogin } from '../utils';
import { AuthContext } from "../Context/AuthContext";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { doRequest } from "../utils/doRequest";
import BgLiveImage from "../Components/Ui/BgLiveImage";

const UserLoginAccess = React.lazy(() => import('../Components/UserLoginAccess'));
const ExhibitorLoginForm = React.lazy(() => import('../Components/ExhibitorLoginForm'));

export default function ExhibitorLogin() {

  const authContext = useContext(AuthContext);
  const loginHandler = () => { authContext.login(); };
  const [isLoading, setLoading] = useState(0);

  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/";

  var CryptoJS = require("crypto-js");
  var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

  function setCookie(cname, cvalue) {
    const d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const hash = new URLSearchParams(location.search).get("hash");
  const status = new URLSearchParams(location.search).get("status");

  useEffect(() => {
    
    if (isLogin()) {
      return <Navigate replace to="/live" />;
    }

    if (hash) {
      setLoading(1);
      doRequest('Server','server.php','GET', {
        type: "confirmLogin",
        hash: hash,
        status: status
      },'',(err,data) => {
        console.log(err);
        console.log(data.response);
        if (data.response == "Success" && data.responseArr.success) {
            
            let token = data.responseArr.response.session;
            let uid = data.responseArr.response.uid.toString();
            setLoading(0);
            if (uid) {
              doRequest('React','/lib/storage/content/Users.json','GET',{},'',(err,data) => {  
                data.forEach((user) => {
                  if (uid == user.uid) {
                    var ciphertext = CryptoJS.AES.encrypt(uid, secretKey).toString();
                    setCookie("fe_typo_user", token);
                    sessionStorage.setItem("token", token);
                    sessionStorage.setItem("auth", ciphertext);
                    localStorage.setItem("contextVar", ciphertext);
                    loginHandler();
                    navigate("/live");
                  }
                });
              });
            }
        } else {
          setLoading(0);     
        }
      } );      
    } else {
      // console.log('no hash set');
    }

    
    document.body.classList.add('backend');
    return () => {
      document.body.classList.remove('backend');
    }

  }, []);

  if (isLoading === 1) {
    return <div className="App">Loading...</div>;
  }

  return (
    <div className="base-box fixed" id="page-top">
      <Header basic={true} />

      <div className="base-item cf">
          <section className="hero-content small-hero flex-box stretch bg-4">
            <div className="bg-image">
              <figure className="image-box">
                <BgLiveImage />
              </figure>
            </div>
          </section>
            <main className="main-content">
              <div className="tx-exhibitor">
               
                <section className="modul login-box">
                  <div className="item-box pd-5 flex-box center-center">
                    {authContext.isAuth ? (
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <UserLoginAccess isExhibitor={true} />
                      </React.Suspense>
                    ) : (
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <ExhibitorLoginForm />
                      </React.Suspense>
                    ) }
                  </div>
                </section>
              </div>
          </main>
        </div>
      
      <Footer />

    </div>
  );
}
