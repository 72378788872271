import React, {createContext, useEffect, useState} from "react";
import { decryptedUserId, isLogin } from "../utils";
import { doRequest } from "../utils/doRequest";


export const BookmarkContext = createContext({
    bookmark: [],
    addBookmark: () => {},
    removeBookmark: () => {}
});



const BookmarkContextProvider = props => {
    const [bookmark, setBookmark] = useState([]);

    useEffect(() => {       
        let isSubscribed = true;
        if(bookmark.length == 0 && isSubscribed && isLogin()) {
            // doRequest('Server', 'server.php', 'GET', { type: 'bookmarkUsers', user: decryptedUserId()}, true, (err,data) => {
            //     if (data?.response) {
            //         setBookmark(data?.response);
            //     }
            // })
            doRequest('React', '/lib/storage/users/' + decryptedUserId() + '/bookmark.json' , 'GET', {}, true, (err,bookmarks) => {
                setBookmark(bookmarks);
            });
        }
        
        return () => (isSubscribed = false)

    }, []);

    return (
        <BookmarkContext.Provider value={{bookmark: bookmark, setBookmark: setBookmark}}>
            {props.children}
        </BookmarkContext.Provider>
    )
}

export default BookmarkContextProvider;