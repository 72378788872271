import React, {createContext, useEffect, useState} from "react";
import { isLogin } from "../utils";
import { doRequest } from "../utils/doRequest";



export const LabelContext = createContext({
    label: {},
    setlabel: () => {}
});

const LabelsContextProvider = props => {
    const [label, setlabel] = useState({});
    
    useEffect(() => {       
        let isSubscribed = true;
        if(label.length !== 0  && isSubscribed && isLogin()) {
            doRequest(
                'React',
                'lib/storage/content/labels.json',
                'GET', 
                {},
                false,
                (err,data) => {
                    setlabel(data);
                }
            );
        }
        
        return () => (isSubscribed = false)

    }, [isLogin()]);

    return (
        <LabelContext.Provider value={{label: label, setlabel: setlabel}}>
            {props.children}
        </LabelContext.Provider>
    )
}

export default LabelsContextProvider;