import React, { useContext, useState } from 'react'
import { AuthContext } from '../../Context/AuthContext';
import { doRequest } from '../../utils/doRequest';

export default function ExhibitorOverlay() {
  const authContext = useContext(AuthContext);
  const [ submited, setSubmited ] = useState(false);
  const doVoting = (voting) => {
    doRequest(
        'Server',
        'server.php?type=votingToHS',
        'POST', {
            email: authContext.profile.email,
            voting: voting
        },
        '',
        (err, data) => {
            if (data.response.succ == 1) {
                setSubmited(true);
            }
        }
    );
  };
  return (
    <>
        <div className="overlay-title flexbox center-left"></div>
        <div className="col-box">
            <div className="col-item">
                {/* <h3>Bewertung Conference</h3> */}
                <h3>KI-Panel: Die Highlights aus der CCW-Messe auf der Kongressbühne vereint!</h3>
                <p>Zukunftsweisende KI-Lösungen für den Kundenservice im Schnelldurchlauf. Wen möchten Sie kennenlernen? Wählen Sie jetzt!</p>
                <p>Drei Anbieter haben sich bereits im Vorfeld qualifiziert. Über den Vierten entscheiden Sie! Wen möchten Sie im Kongress hören? Stimmen Sie noch bis zum Mittag des ersten Kongresstages darüber ab, wer am zweiten Kongresstag auf der Bühne stehen soll!</p>
            </div>
        </div>
        {!submited ? (
            <>
                <div className="col-box col-2">
                    <div className="col-item">
                        <figure class="image-box">
                            <img src={`${process.env.REACT_APP_ENDPOINT}/images/Kauz.jpg`} alt="Kautz GmbH" />
                        </figure>
                    </div>
                    <div className="col-item">
                        <h3>Kautz GmbH </h3>
                        <p>Wir als Kauz GmbH bewerben uns hiermit auf die Teilnahme am diesjährigen KI-Panel.
                        Kauz bietet Chatbots auf linguistischer Basis und wir freuen uns schon auf die CCW 2023.
                        </p>
                        <p> Als Bewerbung finden Sie ein Video unter folgendem Link: &nbsp; <a href="https://youtu.be/nbEfM5Dx8FQ" target="_blank" >https://youtu.be/nbEfM5Dx8FQ</a> 
                        </p>
                        <p> <a href="https://www.kauz.net" target="_blank" >www.kauz.net</a></p>
                        <button onClick={() => doVoting('Vote for Kauz')}>Vote for Kauz</button>
                    </div>
                </div>
                <div className="col-box col-2">
                    <div className="col-item">
                        <figure class="image-box">
                            <img src={`${process.env.REACT_APP_ENDPOINT}/images/Spitch.jpg`} alt="Spitch GmbH" />
                        </figure>
                    </div>
                    <div className="col-item">
                        <h3>Spitch GmbH</h3>
                        <p>
                            Die Komplexität der Supportanfragen hat stark zugenommen. 
                            Im gleichen Zug sind die Anforderungen an das Servicepersonal enorm gestiegen. 
                            Die moderne Conversational AI Plattform von Spitch stellt sicher, 
                            dass Kunden mit einem Unternehmen über Sprach- und Textsysteme über alle Kanäle interagieren können.
                            Diese basieren auf natürlichem Sprachverständnis (NLU) und ermöglichen dank fortschrittlicher Computermodelle, die sowohl die Bedeutung als auch Stimmung oder Emotionen verarbeiten können, einen offenen Dialog. Virtuelle Assistenten können Fragen des Kunden sofort beantworten, indem sie alle notwendigen Informationen aus einer Wissensdatenbank abrufen. 
                            Bei komplexeren Anliegen leitet das System die Sprach- oder Textinteraktion an das entsprechende Kundendienstteam im Unternehmen weiter. 
                            So schafft Spitch exzellente User Experience, entlastet die Belegschaft und hilft Firmen,
                            Kundenservices zu verbessern und Contact-Center-Kosten zu senken.</p>
                            <p><a href="https://www.spitch.ch" target="_blank" >www.spitch.ch</a></p>
                        <button onClick={() => doVoting('Vote for Spitch')}>Vote for Spitch</button>
                    </div>
                </div>
                <div className="col-box col-2">
                    <div className="col-item">
                        <figure class="image-box">
                            <img src={`${process.env.REACT_APP_ENDPOINT}/images/moinAI.jpg`} alt="moinAI GmbH" />
                        </figure>
                    </div>
                    <div className="col-item">
                        <h3> Moin.ai </h3>
                        <p> Self-Service Lösung KI-Chabot: Die Zukunft im Kundenservice!</p>
                            <p>
                                • Die 3 Schritte zum selbstlernenden KI-Chatbot für Ihr Unternehmen <br />
                                • Kundenanfragen automatisieren und Supportkosten senken <br />
                                • Case-Study: Wie Geberit 25 % der manuell zu beantwortenden Service-Anfragen reduziert hat
                            </p>
                        <p><a href="https://www.moin.ai" target="_blank" >www.moin.ai</a> </p>
                        <button onClick={() => doVoting('Vote for moinAI')}>Vote For moinAI</button>
                    </div>
                </div>
            </>
        ) : (
            <div className="col-box">
                <div className="col-item">
                    <p>Thanks for your vote!!!</p>
                </div>
            </div>
        ) }
    </>
  )
}
