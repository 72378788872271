import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import SmallHero from '../Components/SmallHero'
import { doRequest } from '../utils/doRequest'
import parse from "html-react-parser";
import $ from 'jquery'
import { LoadingContext } from '../Context/LoadingContext'
import { decryptedUserId } from '../utils'
import axios from 'axios'
import Article from '../Components/Ui/Article'
import Whitepaper from '../Components/Ui/Whitepaper'
import Video from '../Components/Ui/Video'
import Podcast from '../Components/Ui/Podcast'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { MyAgendaContext } from './Context/myAgendaContext'

export default function SearchTopics() {

    const { setLoading } = useContext(LoadingContext);
    const { userAgenda, setUserAgenda } = useContext(MyAgendaContext);
    let [messageCount, setMessageCount] = useState(0);
    const [userId, setUserId] = useState(0);

    let decryptedData = '';

    if (localStorage.getItem('contextVar')) {
        var CryptoJS = require("crypto-js");
        var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
        var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('contextVar')?.toString(), secretKey);
        if (bytes.toString(CryptoJS.enc.Utf8)) {
            decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            //console.log('decryptedData : '+ decryptedData)
        }
    }
    var chatUrl = process.env.REACT_APP_CHAT_ENDPOINT + "Chat.php";
    let getMessagesFromChat = function () {
        var currUser = $('#currUser').val();
        var myKeyVals = {
            fetchMessages : 1,
            to: currUser
        }

        var saveData = $.ajax({
            type: "POST",
            url: chatUrl,
            data: myKeyVals,
            dataType: "text",
            dataType : 'json',
            success: function(resultData){
                // console.log(resultData);
                let totalCount = resultData.filter(function(value) { return value.m_to == currUser && value.is_read == 0 }).length;

                // console.log('totalCount ' + totalCount )
                // if (parseInt(totalCount) > 0) {
                //     setMessageCount(totalCount)
                // }
                
                // if (typeof window.top.$ === 'function') {
                    let p = window.top;
                    $('.matchmaking-counter', window.parent.document).html(totalCount);
                    let currentRequests = parseInt($('#currentRequests', window.parent.document).val());

                    if (totalCount > currentRequests && $('.search-notification').val() == '') {
                        $('#currentRequests', window.parent.document).val(totalCount);
                        setMessageCount(totalCount)

                        // if($("#notification-detial").css('display') == 'none') {
                        //     p.$('.chat-notifications #overlay-iframe').attr('src', p.$('.chat-notifications #overlay-iframe').attr('src'));
                        // }

                    }

                // }
                //  console.log('message Count ' + messageCount );


                $('#notification-results .notification-item').each(function() {
                    var to = $(this).attr('data-to');
                    var from = $(this).attr('data-from');

                    var length = resultData.filter(function(value) { return value.m_from == to && value.m_to == from && value.is_read == 0 }).length

                    $(this).find('.button-count').html(length>0?length:'');
                    var allMessages = resultData.filter(function(value) {
                            //return value.m_from == to && value.is_read == 0
                            return (value.m_from == to && value.m_to == from) || (value.m_from == from && value.m_to == to)
                    });

                    var time = 0;
                    var message = '';
                    var msgTime = '';
                        if (allMessages.length) {
                        var message = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['message'];

                        var time = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['tstamp'];


                        var msgTime = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['time'];
                    }


                    

                    $(this).find('.button-box').html(message);
                    $(this).find('.time-box').html(msgTime);
                    $(this).attr('data-time', time);
                });

                var sortedHtml = $('#notification-results .notification-item')
                .sort((a,b) => $(b).data("time") - $(a).data("time"))
                $("#notification-results").html(sortedHtml)
            }
        });
    }

    useEffect(() => {
        document.body.classList.add("live");
        document.body.classList.add("bd-user-profile");

        let isSubscribed = true;
        if (isSubscribed) {
            setUserId(decryptedUserId());
            // getMessagesFromChat();
            // setInterval(getMessagesFromChat, 5000);
        }
      
        return () => {
          document.body.classList.remove("live");
          document.body.classList.remove("bd-user-profile");
          isSubscribed = false;
        }
      }, [])
    
    const {state} = useLocation();
    const [news, setNews] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    // const topicsIds = JSON.parse(localStorage.getItem('searchTopicKey')) || []; 
    const [topicsIds, setTopicsIds] = useState([]);
    const topicsNames = JSON.parse(localStorage.getItem('searchTopicName')) || [];

    const [selectedNews, setSelectedNews] = useState(new Set());

    function getSearchTopicKey() {
        return JSON.parse(localStorage.getItem('searchTopicKey')) || []; 
    }

    useEffect(()=>{
        setIsUpdate(!isUpdate)
        setTopicsIds(getSearchTopicKey());
    },[window.localStorage.getItem("searchTopicKey")])
    
    useEffect(() => {

        let isSubscribed = true;

        if (isSubscribed) {
            doRequest(
                "React",
                "/lib/storage/content/news.json",
                "GET",
                {},
                true,
                (err, data) => {
                        setNews(data.mostViewedJsonContents);
                        setIsUpdate(!isUpdate)
                    }
                );
            }

        return () => { isSubscribed = false; };

    }, []);

    useEffect(() => {
        
        let selection = new Set();
        console.log('News',news);
        news.map((news) => {
            topicsIds.map((id) => {
                if(news.expertisesUids.includes(parseInt(id))) {
                    selection.add(news);
                }
            })
        })
		
        setSelectedNews(selection)

	}, [topicsIds,isUpdate,news]);

    function NewsItem({item}) {
        if(item.type === 'whitepaper')
            return <Whitepaper company={item.company.uid}  isOverlay={true} key={Math.random()} item={item} />;
        
        if(item.type === 'article')
            return <Article key={Math.random()} item={item} />;
        
        if (item.type === 'video')
            return <Video company={item.company.uid}  isOverlay={true} key={Math.random()} item={item} />;

        if (item.type === 'podcast')
            return <Podcast company={item.company.uid}  isOverlay={true} key={Math.random()} item={item} />;

        return( <h1>Loading....</h1> )
    }

  return (
    <div className="base-box fixed" id="page-top">
        <input type="hidden" id="currUser" value={decryptedData} />
        <Header show />
        <div className="base-item cf">
        <SmallHero />

        <main className="main-content">
            <section className="modul news-masonry-box pd-4 bg-white" id="search-result">
                <div className="item-box">
                    <h5 className="txt-uppercase">Search Result</h5>
                    <p id="topicSearched">Topic Searched: &nbsp; 
                        {topicsNames && Array.from(topicsNames).map((topicItem, index) => (
                            <span>{topicItem}{Array.from(topicsNames).length !== index+1 ? ', ' : ''}</span>
                        ))}
                    </p>
                    <div id="newsroom-container">
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1024: 4}}
                        >
                            <Masonry columnsCount={4} className="masonry">
                                {console.log(selectedNews)}
                                {selectedNews && Array.from(selectedNews).map((item, index) => (
                                    <NewsItem key={'random'+ index} item={item} />
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
                </div>
            </section>
        </main>
        </div>
        <Footer />
  </div>
  )
}
