import BgLiveImage from "./Ui/BgLiveImage";

export default function SmallHero() {
    return (
        <section className="hero-content small-hero flex-box stretch bg-4">
			<div className="bg-image">
				<figure className="image-box">
					<BgLiveImage />
				</figure>
			</div>
		</section>        
    );
}