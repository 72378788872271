import React, { Fragment } from 'react'
import parse from "html-react-parser";
import { Link } from 'react-router-dom';

export default function Article({item}) {
  return (
    <div className="flex-item masonry-item narrow-item bg-10 company-live-ajax cl-white" >
        <div className="box-title">
            <Link to={`/company/${item.company.uid}#article-content`} className="button">
                <span >Article</span>
                
                <input
                    type="hidden"
                    className="activity-title"
                    value="{item.title}"
                />
            </Link>
        </div>
        
        {item.image? (
            <figure className="image-box">
                <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + item.image} alt={item.title} />
            </figure>
        ) : ''}

        <div className="text-box">
            <h4>{item.title}</h4>
            {parse(item.articleintro)}
        </div>
        <div className="product-overview-box cl-white">
            {item.expertise && item.expertise.map((topic, index) => (
                <Fragment key={index}><span> # {topic}</span> <br /></Fragment>
            ) )}
        </div>
    </div>
  )
}
