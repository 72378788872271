import axios from "axios";
import { CryptoJS }  from 'crypto-js';
var FormData = require('form-data');

export function hashString(user) {
    var hash = CryptoJS.SHA256(user);
    return hash.toString();
}

/**
 * Send request to the server 
 * 
 * @param {string} endpoint - Server - will point to the process.env.REACT_APP_SERVER_ENDPOINT
 * @param {string} endpoint - React - will point to  the process.env.REACT_APP_ENDPOINT
 * @param {*} url 
 * @param {string} method - GET POST PUT
 * @param {*} body 
 * @param {*} withCredentials 
 * @param {*} callback 
 */
export function doRequest(endpoint, url, method, body, withCredentials, callback) {
    let baseurl = process.env.REACT_APP_SERVER_ENDPOINT;
    // let headers = { Authorization: process.env.REACT_APP_HTACCESS_AUTHORIZATION }

    if(endpoint == 'typeNum' && method == "typeNum-GET") 
    {
      baseurl = process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS; 

      var bodyFormData = new FormData();
      for (const property in body) {
        bodyFormData.append('tx_user_leadandanalytics['+ property +']', body[property]);
      }
      
      fetch(baseurl + url, {
        method: 'POST',
        body: bodyFormData
      }).then(res => res.json())
        .then(res => callback( null,res));

    }

    if(endpoint == 'Server')
    {
      baseurl = process.env.REACT_APP_SERVER_ENDPOINT;
      // headers = {
      //   "username": process.env.REACT_APP_HTACCESS_USERNAME,
      //   "password": process.env.REACT_APP_HTACCESS_PASSWORD,
      //   "Content-Type" : 'multipart/form-data'
      // }
    }

    if(endpoint == 'React')
    {
       baseurl = process.env.REACT_APP_ENDPOINT;
    }
    
    if(method == 'XHR') {
      var xhr = new XMLHttpRequest();
      
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          callback( null,xhr.response);
        }
      }

      xhr.open("POST", baseurl + url);
      xhr.setRequestHeader("username", process.env.REACT_APP_HTACCESS_USERNAME);
      xhr.setRequestHeader("password", process.env.REACT_APP_HTACCESS_PASSWORD);
      xhr.send(body);
    }

    if(method == 'POST') {
      
      axios
        .post( baseurl + url, 
          {
              ...body,
              v: process.env.REACT_APP_V,
          }
        )
        .then((res) => {
            callback( null,res.data);
        })
        .catch((err) => {
            console.log(err);
            callback( err,null);
        });

    }


    if(method == 'GET') {

      fetch(baseurl + url + '?' + new URLSearchParams({
        ...body
        }))
        .then((response) => response.json())
        .then((res) => {
          callback( null,res);
        });

      // axios
      //   .get( baseurl + url, 
      //     {
      //       auth: {
      //         username: "ccw",
      //         password: "relaunch",
      //       },
      //       params: {
      //         ...body,
      //         v: process.env.REACT_APP_V,
      //         random: Date.now()
      //       },
      //     }
      //   )
      //   .then((res) => {
      //       callback( null,res.data);
      //   })
      //   .catch((err) => {
      //       console.log(err);
      //       callback( err,null);
      //   });

    }


    
}