import React from 'react'

export default function BgLiveImage() {
  return (
    <img
        srcSet={`${process.env.REACT_APP_ENDPOINT}/images/bg-live-790.jpg 790w, ${process.env.REACT_APP_ENDPOINT}/images/bg-live-1080.jpg 1080w, ${process.env.REACT_APP_ENDPOINT}/images/bg-live.jpg 1680w`}
        sizes="(max-width: 1680px) 100vw, 1680px"
        src={`${process.env.REACT_APP_ENDPOINT}/Public/images/bg-live.jpg`}
    />
  )
}
