import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { doRequest } from '../utils/doRequest'
import SmallHero from '../Components/SmallHero'

import parse from "html-react-parser";

import { decryptedUserId, getCookie, jumpToSection } from '../utils';
import Matchlinks from '../Matchmaking/Matchlinks'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { BookmarkContext } from '../Context/BookmarkContext';
import { AuthContext } from '../Context/AuthContext';
import overlayContext from '../Context/overlayContext';
import { ChatContext } from '../Context/ChatContext';
import { BarLoader, BeatLoader } from 'react-spinners';

export default function UserInfo() {
    let { userId } = useParams();
    let isSubscribed = true;
    const authContext = useContext(AuthContext);
    const { showOverlay, setShowoverlay, setOverlayData } = useContext(overlayContext); 
    const { chatUserDetail, setChatUserDetail, messageObj, setMessageObj } =  useContext(ChatContext);
    const [ loggedInUserId, setLoggedinUserId ] = useState();

    const [ match, setMatch ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    
    const bookmarkContext = useContext(BookmarkContext);
    var tempBookmark = [];

    const navigate = useNavigate();
    const location = useLocation();
    

    useEffect(() => {
        
        
        document.body.classList.add('live');
        document.body.classList.add('bd-matchmaking');
        setLoggedinUserId(decryptedUserId());

        doRequest('Server', 'server.php', 'GET', { type: 'profileDetials', user: decryptedUserId(), getUserDetialsOf: userId }, true, (err,data) => {
            if(isSubscribed) {
                setMatch(data.response.match);
                setProfile(data.response.profile);
            }
        })
        return () => {
            document.body.classList.remove('live');
            document.body.classList.remove('bd-matchmaking');
            isSubscribed = false;
        }

    },[]);

    function startChat(to, username) {
        setShowoverlay('chat');
        let toUser = to;
        var showDetail = true;
        var from = toUser;
        var online = profile.onlineStatus;
        var to = decryptedUserId();

        let message = { to, from, online, showDetail, username };
        setMessageObj(message);
        document.body.classList.add('chat-detail');
        
        // setTimeout(() => {
        //     var chatFrame = $('.chat-notifications #overlay-iframe');
        //     chatFrame[0].contentWindow.postMessage(message, '*');    
        // }, 1000);

        doRequest('Server', 'server.php', 'GET', {
            type: 'myContacts',
            user: decryptedUserId(),
            toUser: toUser
        }, '', (err,data) => {console.log(data)} );

    }

    function showEasyappointment() {
        setShowoverlay('EA');
    }


    function toggleBookmark(event) {
        event.preventDefault();
        let bookmarkId = parseInt(event.currentTarget.getAttribute('data-id'));
        
        let operation = 'add';
        if (bookmarkContext.bookmark.indexOf(bookmarkId) == -1) {
            bookmarkContext.setBookmark(bookmarkUsers => [...bookmarkUsers, bookmarkId]);
            // setBookmarkUsers(bookmarkUsers => [...bookmarkUsers, bookmarkId]);
        } else {
            tempBookmark = bookmarkContext.bookmark.filter(function(item) {
                return item !== bookmarkId
            });
            // setBookmarkUsers(tempBookmark);
            bookmarkContext.setBookmark(tempBookmark);
            operation = 'remove';
        }
        
        doRequest('Server', 'server.php', 'GET', { type: 'addBookmarkUser', user: decryptedUserId(),toUser: bookmarkId,operation: operation}, true, (err,data) => {
            console.log()
        })
       
    }
    let bookmarkActive = '';
    if (bookmarkContext.bookmark?.indexOf(parseInt(match.uid)) != -1){
        bookmarkActive = 'active';
    }
    
    let matchClass = 'match';

    if(match.score >= 275 ) {
        matchClass = 'top-match';
    } else if(match.score >= 200  && match?.score < 275) {
        matchClass = 'good-match';
    }

    let username = `${profile.first_name} ${profile.last_name}`;

    if(!profile) {
        return <BeatLoader />;
    }


    return (
    <div className="base-box fixed" id="page-top">
        <Header />
        <div className="base-item" >
            <SmallHero />
        </div>
        <main className="main-content">
            <section className="modul user-matchmaking" id="filter-nav-section">
                <div className="item-box pd-t5 ">
                    <div className="nav-box flex-box wrap">
                        <Matchlinks />
                    </div>
                </div>
            </section>
            {profile && match && (
            <section className="modul user-detail bg-4">
                <div className="item-box pd-5 ">
                    <div className="small-box txt-center pd-b7">
                        <h3 className='no-margin txtx-center'>Get in Touch</h3>
                        <p className="info-text">Found an interesting match? Then there is just one more thing to do: Click on the orange button to start your chat. We wish you a good conversation!</p>
                        <div className="button-box small-box pd-5">
                            <button className="button call-to-action" onClick={() => {startChat(profile.uid, username); setChatUserDetail(profile);}} data-id={profile.uid} ><span className="button-label">Start Chat</span><span className="icon-box"><i className="icon"></i></span></button>
                            {/* {profile.video_company_id ? (
                                <button onClick={() => { 
                                        setOverlayData({ src: `${process.env.REACT_APP_EA_ENDPOINT}?provider=${profile.providerId}&userId=${getCookie('fe_typo_user')}` });
                                        showEasyappointment()
                                }} class="button appointment">Make a Video Chat Appointment</button>
                            ) : ''} */}
                        </div>
                    </div>
                
                    <input type="hidden" className="rocket-chat-iframe-value" value="https://ccw.codersunlimited.com/chat" />
                    <input type="hidden" id="from" value="10" />
                    <input type="hidden" id="to" value="14" />
                    <div className="flex-box wrap user-detail-box bg-white top-match">
                        <div className="flex-item user-image">
                            <figure className="image-box">
                                {profile.image ? (
                                    <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + profile.image} />
                                ): (
                                    <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + 'typo3conf/ext/tsbasetemplate/Resources/Public/images/icon-user.svg'} />
                                )}  
                            </figure>
                            <button className={`button bookmark ${bookmarkActive}`} data-id={match.uid} onClick={toggleBookmark}>
                                <span className="button-label" data-id={match.uid}>Bookmark</span>
                                <span className="icon-box bookmarks" data-id={match.uid}><i className="icon"></i></span>
                            </button>
                        </div>

                        <div className="flex-item flex-box user-information">
                            <div className="user-information-item">
                                <div className="user-nameset">
                                    <div className="user-nameset-info"><strong>{profile.title}  {profile.first_name} {profile.last_name}</strong></div>
                                    <div className="user-nameset-info">{profile &&  profile.position}</div>
                                    <div className="user-nameset-info">{profile.company}</div>
                                    {profile.showemail && profile.email ? (
                                        <div className="user-nameset-info"><span className="content-label">Email: </span>{profile.email}</div>
                                    ) : ''}
                                    {profile.showphone && profile.telephone ? (
                                        <div className="user-nameset-info"><span className="content-label">Phone: </span>{profile.telephone}</div>
                                    ) : '' }
                                </div>
                                <div className="hashtag-box">
                                    <ul className="product-list">
                                        {profile.expertises && profile.expertises.map((expertise, index) => {
                                                return <li key={index}>{expertise.name}</li>;    
                                        })}
                                    </ul>
                                </div>
                                <br />
                                <div>
                                    <strong>{profile.userStatus?.anstext}</strong>
                                </div>
                                <div className="user-status">
                                    
                                    {profile.onlineStatus == 1 ? (<span class="online-state">Online & @CCW</span>) : '' }
                                    {profile.onlineStatus == 2 ? (<span class="online-state">At Home</span>) : '' }

                                    {profile.speaker ? (
                                        <span class="speaker">Speaker</span>
                                    ) : ''}
                                    
                                    {profile.usergroup?.includes('1') == 1
                                    || profile?.usergroup?.includes('3') == 1
                                        ? <span class="exhibitor">Exhibitor</span>
                                        : ''
                                    }
                                </div>
                                <br />

                                <div className="text-box">
                                    {profile.description ? (<><h5>About Me</h5> {parse(profile.description)}</>): ''}
                                </div>
                                
                            </div>
                        </div>  
                        <div className={`score-box ${matchClass}`}>
                            <span className={matchClass}>{matchClass}</span><span className="icon-box"><i className="icon"></i></span>
                        </div>
                        
                    </div>
                    <div className="small-box txt-center pd-b7">
                        {window.history?.state?.key ? (
                            <><button className="button back-button" onClick={() => { { navigate(-1) }}} ><span className="button-label">Back</span></button>&nbsp;&nbsp;</>
                        ): ''}
                        <div className="button-box small-box pd-5">
                            <button className="button call-to-action" onClick={() => {startChat(profile.uid, username); setChatUserDetail(profile);}} data-id={profile.uid} ><span className="button-label">Start Chat</span><span className="icon-box"><i className="icon"></i></span></button>
                            {/* {profile.video_company_id ? (
                                <button onClick={() => { 
                                        setOverlayData({ src: `${process.env.REACT_APP_EA_ENDPOINT}?provider=${profile.providerId}&userId=${getCookie('fe_typo_user')}` });
                                        showEasyappointment()
                                }} class="button appointment">Make a Video Chat Appointment</button>
                            ) : ''} */}
                        </div>
                    </div>
                </div>
            </section>
            )}
        </main>
        <Footer />
        </div>
  )
}
