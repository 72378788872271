import axios from "axios";
import React, {createContext, useEffect, useState} from "react";
import { doRequest } from "../utils/doRequest";

export const ChatContext = createContext({
    chatCount: {},
    setChatCount: () => {},
    chatUserDetail: {},
    messageObj: null,
    setChatUserDetail: () => {},
    setMessageObj: () => {}
});

const ChatContextProvider = props => {
    const [chatCount, setChatCount] = useState(0);

    const [chatUserDetail, setChatUserDetail] = React.useState({});
    const [messageObj, setMessageObj] = React.useState(null);

    var CryptoJS = require("crypto-js");

    async function getChatCount() {
        if(chatCount == 0 && localStorage.getItem('contextVar')) {

            let decryptedData = '';
            var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('contextVar').toString(), process.env.REACT_APP_ENCRYPTION_KEY);
       
            if (bytes.toString(CryptoJS.enc.Utf8)) {
                decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                //console.log('decryptedData : '+ decryptedData)
            }
            
            let headersList = {
                "Content-Type": "application/x-www-form-urlencoded" 
            }
            
            let bodyContent = "fetchMessagesCount=1&to="+decryptedData;
            
            let reqOptions = {
                url: process.env.REACT_APP_CHAT_ENDPOINT + "Chat.php",
                method: "POST",
                headers: headersList,
                data: bodyContent,
            }
            
            let response = await axios.request(reqOptions);
            // console.log(response.data);
            let resultData = response.data;
            if (resultData) {
                // let totalCount = resultData.filter(function(value) { return value.m_from == decryptedData && value.is_read == 0 }).length;
                // setChatCount(totalCount);
                setChatCount(resultData.count);
            }
                   
        }
    }
    
    useEffect(
        async () => {
        getChatCount();
        const interval = setInterval(function () {
            getChatCount();
        }, 10000);
        return () => {
            clearInterval(interval);
        }

    }, []);

    return (
        <ChatContext.Provider value={{chatCount: chatCount, setChatCount: setChatCount, chatUserDetail: chatUserDetail, setChatUserDetail: setChatUserDetail, messageObj: messageObj, setMessageObj: setMessageObj}}>
            {props.children}
        </ChatContext.Provider>
    )
}

export default ChatContextProvider;