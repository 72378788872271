import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import parse from "html-react-parser";

import ProgramContext from "./Context/programContext";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId, StreamName } from "../utils";
import moment from "moment/moment";
import overlayContext from "../Context/overlayContext";
import { MyAgendaContext } from "./Context/myAgendaContext";
import { LabelContext } from "../Context/LabelsContext";
import { AuthContext } from "../Context/AuthContext";

const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const CryptoJS = require("crypto-js");

export default function ProgramSection() {
  const { setShowoverlay, setOverlayData } = useContext(overlayContext);

  const [streamDates, setStreamDates] = useState([]);
  // const [hallLabel, setHallLabel] = useState([]);]
  const { label } = useContext(LabelContext);
  const [stream1, setStream1] = useState([]);
  const [stream2, setStream2] = useState([]);
  const [stream3, setStream3] = useState([]);

  const [filteredStream1, setFilteredStream1] = useState();
  const [filteredStream2, setFilteredStream2] = useState();
  const [filteredStream3, setFilteredStream3] = useState();

  const [activeDay, setActiveDay] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [index1, setIndex1] = useState(2);
  const [index2, setIndex2] = useState(2);
  const [index3, setIndex3] = useState(0);

  const [userId, setUserId] = useState(0);

  const { userAgenda, setUserAgenda } = useContext(MyAgendaContext);
  const { program, setProgram } = useContext(ProgramContext);
  const authContext = useContext(AuthContext);
  const { profile } = authContext;

  useEffect(() => {
    
    let isSubscribed = true;

    setUserId(decryptedUserId());

    doRequest('React','/lib/storage/content/program.json','GET',{},'',(err,data) => { 
        if (isSubscribed) {
          setProgram(data.streams);
          setStreamDates(data.streamDates);
          // setHallLabel(label);
          setStream1(data.streams.stream1);
          setStream2(data.streams.stream2);
          setStream3(data.streams.stream3);
          // console.log(Object.keys(data.streams.stream1[Object.keys(data.streams.stream1)[0]])[0])
          // console.log(Object.values(data.streams.stream1)[]);

          let s = data.streams.stream1[Object.keys(data.streams.stream1)[2]][Object.keys(data.streams.stream1[Object.keys(data.streams.stream1)[2]])[0]];
          setActiveDay(s[0].date);
        
          
          setFilteredStream1(data.streams.stream1[Object.keys(data.streams.stream1)[2]]);
          setFilteredStream2(data.streams.stream2[Object.keys(data.streams.stream2)[2]]);
          setFilteredStream3(data.streams.stream3[Object.keys(data.streams.stream3)[0]]);
        }
      });

      return () => (isSubscribed = false)
  }, []);

  /**
   * Set my agenda section on user change
   *
   */

  useEffect(() => {
    if (userId) {
      doRequest('React',"lib/storage/users/" + userId + "/myagenda.json",'GET',{},'',(err,data) => { 
          if (data.eventID) {
            setUserAgenda(data.eventID);
          }
      });
    }
  }, [userId]);

  function handleMainTabChange(id) {
    if (activeTab == id) {
      setActiveTab(0);
    } else {
      setActiveTab(id);
    }
    if (id == 1) {
      let firstArr = stream1[Object.keys(stream1)[2]][Object.keys(stream1[Object.keys(stream1)[0]])[0]];
      setActiveDay(firstArr[0].date);
    }
    if (id == 2) {
      let secondArr = stream2[Object.keys(stream2)[2]][Object.keys(stream2[Object.keys(stream2)[0]])[0]];
      setActiveDay(secondArr[0].date);
    }
    if (id == 3) {
      let thirdArr = stream3[Object.keys(stream3)[0]][Object.keys(stream3[Object.keys(stream3)[0]])[0]];
      setActiveDay(thirdArr[0].date);
    }
  }

  function handleTabChange(e, id) {
    e.preventDefault();
    let elem = "program-container";
    let sectionElem = document.getElementById(elem);
    if (sectionElem) {
        sectionElem.scrollIntoView();
    }
    switch (id) {
      case 1:
        setIndex1(streamDates[1].indexOf(e.target.innerText));
        let filter1 = stream1[Object.keys(stream1)[streamDates[id].indexOf(e.target.innerText)]];
        setFilteredStream1(filter1);
        let index1 = Object.keys(stream1).indexOf(e.target.innerText)
        let activeStreamDate1 = stream1[Object.keys(stream1)[index1]][Object.keys(stream1[Object.keys(stream1)[index1]])[0]];
        setActiveDay(activeStreamDate1[0].date);
        break;

      case 2:
        setIndex2(streamDates[2].indexOf(e.target.innerText));
        let filter2 = stream2[Object.keys(stream2)[streamDates[id].indexOf(e.target.innerText)]];
        setFilteredStream2(filter2);
        let index2 = Object.keys(stream2).indexOf(e.target.innerText)
        let activeStreamDate2 = stream2[Object.keys(stream2)[index2]][Object.keys(stream2[Object.keys(stream2)[index2]])[0]];
        setActiveDay(activeStreamDate2[0].date);
        break;
      case 3:
        setIndex3(streamDates[3].indexOf(e.target.innerText));
        let filter3 = stream3[Object.keys(stream3)[streamDates[id].indexOf(e.target.innerText)]];
        setFilteredStream3(filter3);
        let index3 = Object.keys(stream3).indexOf(e.target.innerText)
        let activeStreamDate3 = stream3[Object.keys(stream3)[index3]][Object.keys(stream3[Object.keys(stream3)[index3]])[0]];
        setActiveDay(activeStreamDate3[0].date);
        break;

      default:
        break;
    }
  }

  function toggleMyagenda(id, val) {
    
    let action = 'add';
    if(!val) { action = 'delete'; }

    doRequest('Server', 'server.php', 'GET', { 
          eventId: id,
          type: "myagenda",
          user: userId,
          checked: val,
    }, true, (err,data) => {

        if(err) { console.log(err) }
        doRequest('React', "lib/storage/users/" + userId + "/myagenda.json", 'GET', { 
          eventId: id,
          type: "myagenda",
          user: userId,
          checked: val,
        }, true, (err,data) => {
          setUserAgenda(data.eventID);
        });

      

    });

  
  }

  function FirstTabPagination({ id }) {
    let activeIndex = 0;
    if (id == 1) {
      activeIndex = index1;
    }
    if (id == 2) {
      activeIndex = index2;
    }
    if (id == 3) {
      activeIndex = index3;
    }

    return (
      <div key={id} className="program-pagination flex-box flex-item box-100 pagination-1-container">
        {streamDates &&
          streamDates[id] &&
          streamDates[id].map((date, index) => (
            <button
              onClick={(e) => handleTabChange(e, id)}
              value={date}
              className={`program-day ${
                index == activeIndex && "active"
              }`}
              data-id={index}
              data-day={index}
              key={index}
            >
              <span className="button-label">{date}</span>
              <span className="icon-box">
                <i className="icon"></i>
              </span>
            </button>
          ))}
          {id == 3 ? (
            <button className="program-day">
                  <a
                    href={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}/fileadmin/user_upload/Agenda_conference.pdf`}
                    target="_blank"
                    className="create-pdf"
                    style={{textDecoration: 'none'}}
                  >
                    <span className="button-label cl-white">Download Program PDF</span>
                    <span className="icon-box">
                      <i className="icon"></i>
                    </span>
                  </a>
            </button>
          ): (
            <button className="program-day">
                  <a
                    href={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}/fileadmin/user_upload/Agenda_tradeshow_speakers_forum.pdf`}
                    target="_blank"
                    className="create-pdf"
                    style={{textDecoration: 'none'}}
                  >
                    <span className="button-label cl-white">Download Program PDF</span>
                    <span className="icon-box">
                      <i className="icon"></i>
                    </span>
                  </a>
            </button>
          ) }
            
      </div>
    );
  }

  function Streams({ stream, tab }) {
    // console.log('Streams =>  '+ tab, stream)
    if (stream) {
      return Object.keys(stream).map((val, index) => {
        return (
          <Fragment key={index.toString()}>
            <div className="program-date-time flex-item box-100 active-date">
              <h3>{activeDay}</h3>
            </div>
            {tab && tab == 1 && (
              <div className="flex-box box-100 halls">
                <div className="form-row flex-item box-50 hall-1">
                  <h3 className="res-hide regular">{label[1]}</h3>
                </div>
                <div className="form-row flex-item box-50 hall-2">
                  <h3 className="res-hide regular">{label[2]}</h3>
                </div>
              </div>
            )}
            {tab && tab == 2 && (
              <div className="flex-box box-100 halls">
                <div className="form-row flex-item box-50 hall-item hall-3">
                  <h3 className="res-hide regular">{label[3]}</h3>
                </div>
                <div className="form-row flex-item box-50 hall-item hall-4">
                  <h3 className="res-hide regular">{label[4]}</h3>
                </div>
              </div>
            )}
            {tab && tab == 3 && (
              <div className="flex-box box-100 halls">
                <div className="form-row flex-item box-50 hall-5">
                  <h3 className="res-hide regular">{label[5]}</h3>
                </div>
                <div className="form-row flex-item box-50 hall-6">
                  <h3 className="res-hide regular">{label[6]}</h3>
                </div>
              </div>
            )}

            <div className="program-time flex-item box-100">
              <strong>{val}</strong>
              <hr />
            </div>

            {val &&
              stream[val].map((streamItem, index) => (
                <div
                  key={index}
                  data-id={streamItem.stage}
                  className={`form-row flex-item box-50 hall-${streamItem.stage} grey-stage-event`}
                >
                  <h3 className="res-show regular">
                    {label[streamItem.stage]}
                  </h3>

                  <div className="programm-event-box">
                    <div className="field-item standard-radio">
                      <input
                        type="checkbox"
                        className="event-hall-check"
                        name={`event-hall-${streamItem.eventId}`}
                        id={`event-hall-${streamItem.eventId}`}
                        value={streamItem.eventId}
                        checked={
                          userAgenda &&
                          userAgenda.includes(streamItem.eventId) &&
                          "checked"
                        }
                        onChange={() => {}}
                      />
                      <label
                        onClick={(e) =>
                          toggleMyagenda(
                            streamItem.eventId,
                            userAgenda.includes(streamItem.eventId) ? false : true
                          )
                        }
                        htmlFor={`event-hall-${streamItem.eventId}`}
                        className="checkbox-label"
                      >
                        Add to MyAgenda
                      </label>
                    </div>

                    <div className="title-box pd-2">
                      {streamItem.detitle && <span className="title-item-de">{parse(streamItem.detitle)}<br/></span>}
                      <span className="title-item-en">{parse(streamItem.title)}</span>
                    </div>
                   
                      
                    <div className="stream-info-box">
                      <span className="firstname">{streamItem.firstname}</span>
                      <span className="lastname">{streamItem.lastname}</span>
                      {/* <span className="companyname">{streamItem.company}</span> */}
                    </div>
                      
                    <div className="programm-text-box">
                      {streamItem.dedescription && parse(streamItem.dedescription)}
                      {streamItem.description && parse(streamItem.description)}

                      {streamItem.delongtext && parse(streamItem.delongtext)}
                      {streamItem.longtext && parse(streamItem.longtext)}
                    </div>
                    
                    {streamItem.speakers.map((speaker) => (
                      (speaker.firstname && speaker.lastname) ?
                        <p>{speaker.firstname} {speaker.lastname}, {speaker.position}, {speaker.company}</p> : ''
                    ))}

                    <br />
                    <div className="cooming-soon"> 

                    {/* {((streamItem.stage == 5 || streamItem.stage == 6) &&  profile.conferenceuser) ?
                    (<>Confrence user</>):
                    (<></>)} */}

                      {streamItem.is_livestream ? (
                        <p>No Livestreaming</p>
                      ) : (
                          streamItem.starttimestamp <= moment().tz("Europe/Berlin").unix() 
                          &&  moment().tz("Europe/Berlin").unix() <= streamItem.endtimestamp 
                          ?
                          (
                            (streamItem.stage == 5 || streamItem.stage == 6) ? (
                              (profile.conferenceuser ? (
                                (streamItem.dehtmllivestream ? (
                                  <>
                                    <a 
                                      onClick={() => {
                                        setOverlayData({
                                          stream: streamItem.uid, 
                                          streamUrl: streamItem.streamUrl, 
                                          companyId : streamItem?.companyId, 
                                          stage: streamItem.stage,
                                          title: streamItem.title,
                                          showConsent : true,
                                        stageTitle: <StreamName stream={streamItem} />
                                      }); setShowoverlay('livestream')}}
                                      style={{cursor: 'pointer'}}
                                      >
                                      Start Live Stream (en)
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a 
                                      onClick={() => {
                                        setOverlayData({
                                          stream: streamItem.uid, 
                                          streamUrl: streamItem.streamUrl, 
                                          companyId : streamItem?.companyId, 
                                          stage: streamItem.stage,
                                          title: streamItem.title,
                                          showConsent : true,
                                          showDeStream: true,
                                        stageTitle: <StreamName stream={streamItem} />
                                      }); setShowoverlay('livestream')}}
                                      style={{cursor: 'pointer'}}
                                      >
                                      Start Live Stream (de)
                                    </a>
                                  </>
                                ) : (
                                  <a 
                                    onClick={() => {
                                      setOverlayData({
                                        stream: streamItem.uid, 
                                        streamUrl: streamItem.streamUrl, 
                                        companyId : streamItem?.companyId, 
                                        stage: streamItem.stage,
                                        title: streamItem.title,
                                        showConsent : true,
                                      stageTitle: <StreamName stream={streamItem} />
                                    }); setShowoverlay('livestream')}}
                                    style={{cursor: 'pointer'}}
                                    >
                                    Start Live Stream
                                  </a>
                                ))
                              ) : (
                                // Conference User, can not watch live stream
                                ''
                              ))
                            ) : 
                            (
                            <a 
                              onClick={() => {
                                setOverlayData({
                                  stream: streamItem.uid, 
                                  streamUrl: streamItem.streamUrl, 
                                  companyId : streamItem?.companyId, 
                                  stage: streamItem.stage,
                                  title: streamItem.title,
                                  showConsent : true,
                                stageTitle: <StreamName stream={streamItem} />
                              }); setShowoverlay('livestream')}}
                              style={{cursor: 'pointer'}}
                              >
                              Start Live Stream
                            </a>
                            )
                          )
                          :
                          moment().tz("Europe/Berlin").unix() <= streamItem.starttimestamp  
                          ?
                          (<span className="strong">Livestream starts soon</span>) 
                          :
                          (
                            streamItem.archivemp4? (
                              ((streamItem.stage == 5 || streamItem.stage == 6) &&  profile.conferenceuser) ? (
                                streamItem.dearchivemp4 != '' ? (
                                  <>
                                    <a 
                                    onClick={() => {
                                      setOverlayData({
                                        stream: streamItem.uid, 
                                        streamItem: streamItem.streamUrl, 
                                        companyId : streamItem?.companyId, 
                                        stage: streamItem.stage,
                                        title: streamItem.title,
                                        archivelive: streamItem.archivemp4, 
                                        showConsent : true
                                      }); setShowoverlay('archiveLivestream')}}
                                      style={{cursor: 'pointer'}}
                                      className="recording-button"
                                      >
                                      Show Recording (en)
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a 
                                    onClick={() => {
                                      setOverlayData({
                                        stream: streamItem.uid, 
                                        streamItem: streamItem.streamUrl, 
                                        companyId : streamItem?.companyId, 
                                        stage: streamItem.stage,
                                        title: streamItem.title,
                                        dearchivelive: streamItem.dearchivemp4, 
                                        showConsent : true,
                                        showDeStream: true
                                      }); setShowoverlay('archiveLivestream')}}
                                      className="recording-button"
                                      style={{cursor: 'pointer'}}
                                      >
                                      Show Recording (de)
                                    </a>
                                  </>
                                ) : (
                                  <a 
                                  onClick={() => {
                                    setOverlayData({
                                      stream: streamItem.uid, 
                                      streamItem: streamItem.streamUrl, 
                                      companyId : streamItem?.companyId, 
                                      stage: streamItem.stage,
                                      title: streamItem.title,
                                      archivelive: streamItem.archivemp4, 
                                      showConsent : true,
                                    }); setShowoverlay('archiveLivestream')}}
                                    style={{cursor: 'pointer'}}
                                    className="recording-button"
                                    >
                                    Show Recording
                                  </a>
                                )
                              )
                              : (
                                <a 
                                  onClick={() => {
                                    setOverlayData({
                                      stream: streamItem.uid, 
                                      streamItem: streamItem.streamUrl, 
                                      companyId : streamItem?.companyId, 
                                      stage: streamItem.stage,
                                      title: streamItem.title,
                                      archivelive: streamItem.archivemp4, 
                                      showConsent : true,
                                    }); setShowoverlay('archiveLivestream')}}
                                    style={{cursor: 'pointer'}}
                                    className="recording-button"
                                    >
                                    Show Recording
                                  </a>
                              )
                            ):
                          <span className="strong">Recording will be available soon</span>
                          ) 
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </Fragment>
        );
      });
    }

    return <>Loading...</>;
  }

  return (
    <section style={{'background': '#fff', 'paddingTop': '2em'}} className="modul program-tabs" id="program-container">
      <div className="item-box">
        <h5 className="txt-uppercase" >Program</h5>
        <div className="tab-box link-box flex-box wrap">
          <div className={`tab-item ${activeTab == 1 ? 'active' : '' }`}>
            <button
              onClick={() => {
                handleMainTabChange(1);
              }}
              className="tabbutton tradeshow"
              id="tradeshow"
            >
              <span>{process.env.REACT_APP_PROGRAM_TAB_1}</span>
              <span class="tradeshow-button"><svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg></span>
            </button>
          </div>
          <div className={`tab-item ${activeTab == 2 ? 'active' : '' }`}>
            <button
              onClick={() => {
                handleMainTabChange(2);
              }}
              className="tabbutton teletalk"
              id="speakersforum"
            >
                <span>{process.env.REACT_APP_PROGRAM_TAB_2}</span>
                <span class="speakersforum-button"><svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg></span>
            </button>
          </div>
          <div className={`tab-item ${activeTab == 3 ? 'active' : '' }`}>
            <button
              onClick={() => {
                handleMainTabChange(3);
              }}
              className="tabbutton conference"
              id="conference"
            >             
              <span>{process.env.REACT_APP_PROGRAM_TAB_3}</span>
              <span class="conference-button"><svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg></span>
            </button>
          </div>
        </div>

        <div className={`tab-content bg-7 ${activeTab != 1 ? 'hidden' : '' } `} id="tradeshow-tab-content">
          <div className="tab-content-item pd-3">
            <div className="flex-box wrap programm-select-form">
              <FirstTabPagination id={1} />
              <div
                id="stream1-container"
                className="flex-box wrap programm-select-form"
              >
                <Streams stream={filteredStream1} tab={1} />
              </div>
              <FirstTabPagination id={1} />
            </div>
          </div>
        </div>

        <div className={`tab-content bg-8 ${activeTab != 2 ? 'hidden' : '' } `} id="speakersforum-tab-content">
          <div className="tab-content-item pd-3">
            <div className="flex-box wrap programm-select-form">
              <FirstTabPagination id={2} />
              <div
                id="stream2-container"
                className=" flex-box wrap stream-container programm-select-form"
              >
                <Streams stream={filteredStream2} tab={2} />
              </div>
              <FirstTabPagination id={2} />
            </div>
          </div>
        </div>

        <div className={`tab-content personal-event conference ${activeTab != 3 ? 'hidden' : '' } `} id="conference-tab-content">
          <div className="tab-content-item pd-3">
            <div className="programm-select-form">
              <FirstTabPagination id={3} />
              <div
                id="stream3-container"
                className="flex-box wrap stream-container programm-select-form"
              >
                <Streams stream={filteredStream3} tab={3} />
              </div>
              <FirstTabPagination id={3} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
